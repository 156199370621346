export default {
  views: {
    list: {
      header: {
        title: "Testiranja",
        search_placeholder: "Iskanje ...",
        add_button: "Novo testiranje"
      },
      table: {
        title: "Naziv",
        status: "Stanje",
        responsible: "Odgovorna oseba",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivno",
        options: "Možnosti",
        vincere: "Iz Vincere"
      }
    },
    details: {
      competence_title: "testiranja",
      header: {
        back_button: "Na listo testiranj",
        edit_button: "Uredi testiranje",
        toggle: {
          title: "Aktivno",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Testiranje lahko {action} tukaj"
        }
      },
      basic: {
        responsible: "Odgovorna oseba",
        take_over: "Prevzemi testiranje",
        project: "Projekt",
        consent: "Tip soglasja",
        deadline: "Zaključek testiranja",
        created: "Ustvarjeno",
        updated: "Posodobljeno",
        users: {
          title: "Uporabniki",
          button: {
            text: "Upravljanje uporabnikov"
          },
          empty_list: "Lista uporabnikov je prazna."
        },
        comment: "Opomba",
        success_message: "Nastavljeni ste kot odgovorna oseba.",
        take_over_popup: {
          title: "Prevzem testiranja",
          description: {
            text1: "Nastavljeni boste kot odgovorna oseba.",
            text2: "Pred prevzemom prosim nastavite datum zaključka tesiranja."
          },
          buttons: {
            date_picker: "Datum zaključka",
            cancel_button: "Prekliči",
            confirm_button: "Prevzemi testiranje"
          }
        },
        users_popup: {
          title: "Upravljanje izvajalcev",
          search: "Iskanje ...",
          table: {
            name: "Ime in priimek",
            presence: "Prisotnost v testiranju"
          }
        }
      },
      candidates_list: {
        employee: "Zaposleni",
        buttons: {
          manage_candidates: {
            title: "Upravljanje kandidatov"
          },
          send_tests: {
            tooltip: "Pošljite teste oz. dostopne podatke<br/>vsem navedenim kandidatom.",
            title: "Pošiljanje testov"
          }
        },
        table: {
          name: "Ime in priimek",
          status: {
            title: "Pošlji Ψ teste",
            not_ordered: "Ni naročen"
          },
          review: "Ψ pregled",
          updated_at: "Datum spremembe",
          options: "Možnosti",
          show_candidate: "Prikaži kandidata",
          send_tests: "Pošlji teste",
          upload_file: "Naloži poročilo",
          uploaded_file: "Naloženo poročilo",
          add_opinion: "Dodaj mnenje",
          impersonate: "Prijavi se v Portal kot uporabnik",
          empty: {
            text1: "Lista kandidatov v testiranju je prazna.",
            text2: "Kandidata lahko dodate tako, da odprete listo za upravljanje kandidatov.",
            text3: "Upravljanje liste kandidatov"
          },
          remove_candidate: "Odstrani kandidata s seznama"
        },
        prop_title: "Kandidati",
        status: {
          open: "Ne poslano",
          in_progress: "V izvajanju",
          sent: "Poslano",
          closed: "Zaprto"
        },
        review: {
          open: "Naročeno",
          closed: "Pripravljeno",
          not_ordered: "Ne naročeno"
        },
        error_message: "Nekaj je šlo po zlu.",
        success_message: "Pošiljanje testov smo postavili v vrsto za pošiljanje.",
        candidates_popup: {
          description: "Prisotnost v testiranju",
          title: "Lista kandidatov",
          table: {
            search: "Iskanje ...",
            buttons: {
              import: "Uvoz kandidatov",
              add: "Dodaj novega kandidata"
            },
            name: "Ime in priimek"
          },
          buttons: {
            save: "Shrani",
            cancel: "Prekliči"
          },
          no_table: "V nastavitvah projekta / testiranja izberite katere uporabnike želite testirati.",
          add_candidate_popup: {
            headline: "Osnovni osebni podatki",
            fields: {
              title: {
                label: "Ime in priimek",
                placeholder: "Vpišite ime in priimek kandidata"
              },
              email: {
                headline: "Elektronski naslov",
                headline_description: "Vpis elektronskega naslova omogoča pošiljanje testov in drugih obveščanj.",
                label: "Elektronski naslov",
                placeholder: "Vpišite elektronski naslov"
              }
            },
            buttons: {
              save: "Shrani kandidata",
              cancel: "Prekliči"
            },
            success_message: "Uspešno ste shranili kandidata."
          }
        },
        impersonate_error_message: "Pri prijavi je prišlo do napake.",
        deadline_prompt: {
          title: "Rok za izpolnitev testiranja",
          message: "Kandidati bodo prejeli elektronsko sporočilo o poslanem testu. V njem bo naveden rok za izpolnitev testiranja. V spodnjem polju nastavite željeni rok za izpolnitev testiranja.",
          cancel: "Prekliči",
          confirm: "Potrdi"
        }
      },
      candidates_drawer: {
        competences: {
          headline: "Vse kompetence",
          content: {
            value_percentile: {
              text: "Percentil",
              title: "P"
            },
            value_denom: {
              text: "Ocena",
              title: "O"
            },
            value: {
              text: "Število surovih točk",
              title: "T"
            },
            value_denom_correction: {
              text: "Korigirana ocena",
              title: "K"
            },
            avg_value: {
              text: "Povprečje vseh točk kompetence",
              title: "M (T)"
            }
          },
          buttons: {
            edit: {
              text: "Korigiraj ocene"
            },
            save: {
              text: "Shrani ocene"
            }
          },
          notify: {
            error: "Pri shranjevanju je prišlo do napake.",
            success: "Uspešno ste shranili korigirane ocene kompetenc"
          }
        },
        candidate_details: {
          testing: {
            headline: "Testiranja kandidata",
            table: {
              title: "Naziv testiranja",
              status: "Stanje",
              responsible: "Odgovorna oseba",
              updated_at: "Posodobljeno"
            }
          },
          projects: {
            headline: "Projekti kandidata",
            table: {
              title: "Naziv projekta",
              responsible: "Odgovorna oseba",
              updated_at: "Posodobljeno",
              list: "Faza"
            },
            micro: "Mikro okolje"
          },
          questionnaires: {
            headline: "Odprti vprašalniki",
            table: {
              title: "Naziv vprašalnika",
              updated_at: "Posodobljeno",
              options: "Možnosti",
              remove: "Izbriši",
              resend: "Pošlji ponovno"
            },
            prompt: {
              title: "Izbris vprašalnika?",
              message: "Ste prepričani da želite izbrisati vprašalnik?",
              confirmBtn: "Izbriši",
              cancelBtn: "Prekliči"
            },
            success_message: "Uspešno ste izbrisali vprašalnik.",
            resend: {
              success: "Vprašalnik je bil ponovno poslan.",
              error: "Pri pošiljanju je prišlo do težave."
            }
          },
          notifications: {
            headline: "Obveščanja",
            table: {
              title: "Naziv obvestila",
              type: "Tip",
              responsible: "Odgovorna oseba",
              updated_at: "Posodobljeno",
              subject: "Zadeva"
            }
          },
          consents: {
            headline: "Soglasja",
            headline_description: "Vsa soglasja uporabnika. Uporabnik do sedaj še ni prejel soglasja.",
            table: {
              title: "Naziv soglasja",
              agreement: "Potrjen",
              updated_at: "Poslan / Potrjen",
              yes: "DA",
              no: "NE"
            }
          }
        },
        overview: {
          basic: {
            headline: {
              candidate: "Podatki o kandidatu",
              employee: "Podatki o zaposlenemu"
            },
            gender: {
              title: "Spol",
              male: "M",
              female: "Ž"
            },
            birthday: "Datum rojstva",
            education: "Stopnja izobrazbe",
            phone: "Telefonska številka",
            email: "Elektronski naslov",
            redirect: {
              candidate: "Prikaži kandidata",
              employee: "Prikaži zaposlenega"
            },
            position: "Naziv oddelka in pozicije",
            address: "Naslov",
            company: "Podjetje",
            career: "Karierni nivo",
            linkedin_url: "LinkedIn povezava",
            employer: "Trenutni delodajalec",
            industries: "Industrija",
            workfields: "Področje dela",
            expectations: "Želena letna bruto plača (EUR)",
            region: "Regija",
            position_title: "Naziv trenutnega delovnega mesta",
            vincere_registration_date: "Datum registracije v Vincere",
            city: "Mesto",
            postal: "Poštna številka",
            country: "Država",
            education_summary: "Povzetek izobraževanja",
            notice_days: "Odpovedni rok (št. dnevov)",
            relocate: "Relocate?",
            yes: "Da",
            skills: "Veščine"
          },
          files: {
            report: {
              tooltip_upload: "Naloži poročilo",
              tooltip_download: "Prenesi poročilo",
              doesnt_exist: "Poročilo ne obstaja"
            },
            cv: {
              tooltip_upload: "Naloži CV",
              tooltip_download: "Prenesi CV",
              doesnt_exist: "CV ne obstaja"
            }
          },
          questionnaires: {
            buttons: {
              upload: "Naloži poročilo",
              uploaded: "Naloženo poročilo",
              send_tests: "Pošlji teste",
              reset_test: "Ponastavi test"
            },
            table: {
              title: "Naziv vprašalnika",
              status: "Status",
              options: "Možnosti",
              duration: "Čas reševanja",
              completed: "Opravljeno",
              tooltip_completed: "Če je uporabnik že rešil vprašalnik<br>(na tem ali katerem koli drugem testiranju),<br>bo prikazana kljukica."
            }
          },
          competences: {
            headline: "Kompetence testiranja",
            content: {
              value_percentile: {
                text: "Percentil",
                title: "P"
              },
              value_denom: {
                text: "Ocena",
                title: "O"
              },
              value: {
                text: "Število surovih točk",
                title: "T"
              }
            },
            no_data: "Graf ni na voljo, ker testiranje ne vsebuje kompetenc."
          },
          intelligence: {
            headline: "Splošna čustvena inteligentnost",
            rate: {
              "0": "podatek ni na voljo",
              "1": "zelo nizko",
              "2": "nizko",
              "3": "povprečno",
              "4": "visoko",
              "5": "zelo visoko"
            }
          },
          status: {
            not_sent: "Ne poslano",
            open: "Poslano",
            in_progress: "V izvajanju",
            closed: "Zaprto"
          },
          error_message: "Nekaj je šlo po zlu.",
          success_message: "Pošiljanje testov smo postavili v vrsto za pošiljanje.",
          update_questionnaires: {
            error_message: "Pri shranjevanju je prišlo do napake.",
            success_message: {
              part1: "Testi kandidata ",
              part2: " so posodobljeni."
            }
          },
          deadline_prompt: {
            title: "Rok za izpolnitev testiranja",
            message: "Kandidati bodo prejeli elektronsko sporočilo o poslanem testu. V njem bo naveden rok za izpolnitev testiranja. V spodnjem polju nastavite željeni rok za izpolnitev testiranja.",
            cancel: "Prekliči",
            confirm: "Potrdi"
          },
          decline_reason: {
            headline: "Razlog za zavrnitev"
          },
          placed_at: {
            headline: "Kandidat je bil izbran "
          }
        },
        opinion: {
          opinion: {
            opinion_headline: "Mnenje o kandidatu na tem projektu",
            no_opinion: "Na tem projektu še ni bilo dodanega mnenja o kandidatu.",
            other_opinion_headline: "Mnenja o kandidatu na drugih projektih",
            no_other_opinion: "Drugi projekti ne vsebujejo mnenja o tem kandidatu."
          },
          buttons: {
            save: "Shrani"
          },
          notify: {
            save: {
              success: "Mnenje je bilo shranjeno.",
              error: "Pri shranjevanju je prišlo do napake."
            }
          }
        },
        summary: {
          summary: {
            summary_headline: "Povzetek o kandidatu na tem projektu",
            no_summary: "Na tem projektu še ni bilo dodanega povzetka o kandidatu.",
            other_summary_headline: "Povzetki o kandidatu na drugih projektih",
            no_other_summary: "Drugi projekti ne vsebujejo povzetka o tem kandidatu."
          },
          buttons: {
            save: "Shrani"
          },
          notify: {
            save: {
              success: "Povzetek je bil shranjen.",
              error: "Pri shranjevanju je prišlo do napake."
            }
          }
        },
        tabs: {
          overview: "Pregled",
          competences: "Kompetence",
          opinion: "Mnenja",
          summary: "Povzetek Ψ",
          other: "Ostalo"
        },
        title: "Kandidat",
        reset_test: {
          error_message: "Nekaj je šlo po zlu.",
          success_message: "Test je bil uspešno ponastavljen."
        }
      },
      stats: {
        headline: "Stanje testiranja",
        headline_description: "Število opravljenih vprašalnikov",
        complete: "Opravljeno",
        buttons: {
          close_testing: "Zaključi testiranje",
          testing_is_closed: "Testiranje zaključeno"
        },
        prompt: {
          title: "Zaključi testiranje",
          message: {
            part1: "Zaključek testiranja je nastavljen na datum ",
            part2: ". Ste prepričani, da želite testiranje predčasno zaključiti?"
          },
          confirmBtn: "Zaključi",
          cancelBtn: "Prekliči",
          error_message: "Pri shranjevanju je prišlo do napake.",
          success_message: "Procedura za zaključevanje testiranja se je pričela."
        }
      },
      candidate_opinion_popup: {
        title: "Mnenje o kandidatu",
        show_more_opinions: "Prikaži mnenja iz drugih projektov",
        hide_more_opinions: "Skrij mnenja iz drugih projektov",
        no_other_opinions: "Ni drugih mnenj o kandidatu",
        buttons: {
          cancel: "Prekliči",
          save: "Shrani"
        }
      }
    }
  },
  popup: {
    title: "Novo testiranje",
    fields: {
      title: {
        label: "Naziv testiranja",
        placeholder: "Vpišite naziv testiranja"
      },
      deadline: {
        label: "Datum zaključka"
      },
      competences: {
        label: "Kompetence"
      },
      questionnaire: {
        headline: "Vprašalniki",
        headline_description: "Prednastavljeni vprašalniki testiranja. Še vedno lahko naknadno spremenite vprašalnike testiranja pri posameznem kandidatu."
      },
      consent: {
        label: "Tip soglasja"
      },
      comment: {
        label: "Opomba"
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      responsible: {
        label: "Odgovorna oseba",
        search: {
          not_found: "Oseba ne obstaja"
        }
      },
      users_type: {
        label: "Udeleženci testiranja",
        placeholder: "Izberite uporabnike"
      },
      users_type_micro: {
        label: "Udeleženci testiranja (mikro okolje)",
        placeholder: "Izberite uporabnike"
      }
    },
    users_type: {
      candidate: "Kandidati",
      companyemployee: "Zaposleni v podjetjih",
      thiscompanyemployee: "Zaposleni v izbranem podjetju",
      employee: "Uporabniki DIGI"
    },
    add_button: "Shrani testiranje",
    update_button: "Shrani testiranje"
  },
  prompts: {
    delete: {
      title: "Izbris testiranja {title}",
      message: "Ste prepričani da želite izbrisati testiranje {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili testiranje {title}",
    updated: "Uspešno ste posodobili testiranje {title}",
    deleted: "Uspešno ste izbrisali testiranje {title}",
    not_found: "Testiranje ni bilo najdeno"
  }
}
