export default {
  views: {
    list: {
      header: {
        title: "Kandidati",
        search_placeholder: "Iskanje ...",
        add_button: "Nov kandidat",
        toggle: {
          label: "Prikaži izbrisane",
          description: "Prikazujem samo izbrisane kandidate"
        }
      },
      table: {
        name: "Ime in priimek",
        education: "Stopnja izobrazbe",
        author: "Avtor",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        options: "Možnosti",
        pernament_delete: "Izbriši za vedno"
      },
      prompts: {
        delete: {
          title: "Izbris kandidata ",
          message1: "Ste prepričani da želite ",
          message2: "za vedno ",
          message3: "izbrisati kandidata",
          cancel: "Prekliči",
          confirm: "Izbriši",
          success: "Uspešno ste izbrisali kandidata"
        }
      }
    },
    details: {
      header: {
        back_button: "Na listo kandidatov",
        edit_button: "Uredi kandidata",
        toggle: {
          title: "Aktiven",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Kandidata lahko {action} tukaj"
        }
      },
      notice: "Kandidat je izbrisan.",
      basic: {
        gender: {
          title: "Spol",
          male: "M",
          female: "Ž"
        },
        birthday: "Datum rojstva",
        education: "Stopnja izobrazbe",
        email: "Elektronski naslov",
        created_by: "Kandidata ustvaril",
        comments: "Opombe",
        career: "Trenutni karierni nivo",
        source: "Identificiran",
        linkedin_url: "LinkedIn povezava",
        employer: "Trenutni delodajalec",
        industries: "Industrija",
        workfields: "Področje dela",
        expectations: "Želena letna bruto plača (EUR)",
        region: "Regija",
        phone: "Telefonska številka",
        position_title: "Naziv trenutnega delovnega mesta",
        vincere_registration_date: "Datum registracije v Vincere",
        address: "Naslov",
        city: "Mesto",
        postal: "Poštna številka",
        country: "Država",
        education_summary: "Povzetek izobraževanja",
        notice_days: "Odpovedni rok (št. dnevov)",
        relocate: "Relocate?",
        yes: "Da",
        buttons: {
          edit: "Uredi"
        },
        notes_popup: {
          title: "Uredi opombe",
          note: "Opomba",
          buttons: {
            confirm: "Shrani",
            add: "Nova opomba",
            remove: "Odstrani opombo"
          }
        },
        skills: "Veščine",
        keyword: "Ključne besede",
        set_of_items_popup: {
          title_skills: "Uredi veščine",
          title_keyword: "Uredi ključne besede",
          buttons: {
            confirm: "Shrani",
            remove: "Odstrani"
          },
          remove_instructions: "Predmet odstranite tako, da kliknete na njega.",
          new_item: {
            label: {
              skills: "Nova veščina",
              keyword: "Nova ključna beseda"
            },
            placeholder: {
              skills: "Vnesite novo veščino",
              keyword: "Vnesite novo ključno besedo"
            }
          }
        }
      },
      tests: {
        headline: "Testiranja",
        headline_description: "Vsa testiranja uporabnika v kronološkem zaporedju. Uporabnik še nima testiranj.",
        table: {
          title: "Naziv testiranja",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      projects: {
        headline: "Projekti",
        headline_description: "Vsi projekti uporabnika v kronološkem zaporedju. Uporabnik še nima projektov.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno",
          list: "Faza"
        },
        micro: "Mikro okolje"
      },
      notices: {
        headline: "Obveščanja",
        headline_description: "Vsa obveščanja uporabnika. Uporabnik do sedaj še ni prejel obvestila.",
        table: {
          title: "Naziv obvestila",
          type: "Tip",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno",
          subject: "Zadeva"
        }
      },
      consents: {
        headline: "Soglasja",
        headline_description: "Vsa soglasja uporabnika. Uporabnik do sedaj še ni prejel soglasja.",
        table: {
          title: "Naziv soglasja",
          agreement: "Potrjen",
          updated_at: "Poslan / Potrjen",
          yes: "DA",
          no: "NE"
        }
      },
      questionnaire: {
        headline: "Odprti vprašalniki",
        headline_description: "Uporabnik nima odprtih vprašalnikov.",
        table: {
          title: "Naziv vprašalnika",
          updated_at: "Posodobljeno",
          options: "Možnosti",
          remove: "Izbriši",
          resend: "Pošlji ponovno"
        },
        prompt: {
          title: "Izbris vprašalnika?",
          message: "Ste prepričani da želite izbrisati vprašalnik?",
          confirmBtn: "Izbriši",
          cancelBtn: "Prekliči"
        },
        success_message: "Uspešno ste izbrisali vprašalnik.",
        resend: {
          success: "Vprašalnik je bil ponovno poslan.",
          error: "Pri pošiljanju je prišlo do težave."
        }
      },
      competences: {
        headline: "Kompetence",
        headline_description1: "Vse kompetence",
        headline_description2: "Uporabnikove kompetence",
        toggle: {
          label: "Prikaži vse"
        },
        buttons: {
          edit: {
            text: "Uredi kompetence"
          },
          save: {
            text: "Shrani kompetence"
          }
        },
        content: {
          value_percentile: {
            text: "Percentil",
            title: "P"
          },
          value_denom: {
            text: "Ocena",
            title: "O"
          },
          value: {
            text: "Število surovih točk",
            title: "T"
          },
          avg_value: {
            text: "Povprečje vseh točk kompetence",
            title: "M (T)"
          }
        },
        notify: {
          error: "Pri shranjevanju je prišlo do napake.",
          success: "Uspešno ste shranili kompetence"
        }
      },
      users: {
        title: "Uporabniki",
        add_button: "Dodaj uporabnika"
      },
      opinions: {
        headline: "Mnenja",
        no_opinions: "Vsa mnenja uporabnika. Uporabnik še nima vpisanega mnenja."
      },
      company_employee_widget: {
        title: "Zaposleni v podjetju",
        edit_button: "Uredi",
        new_button: "Ustvari",
        not_available: "Zaposlenega v podjetju lahko ustvarite samo, če ima kandidat uporabniški račun.",
        available: "Kandidatu dodaj vlogo zaposlenega v podjetju.",
        redirect: "Ogled zaposlenega v podjetju",
        name: "Ime in priimek zaposlenega",
        company: "Podjetje"
      }
    }
  },
  popup: {
    title: "Nov kandidat",
    headline: "Uporabniški podatki",
    headline_description: "Vpis elektronskega naslova omogoča pošiljanje testov in drugih obveščanj.",
    basic_info: "Osnovni osebni podatki",
    additional_info: "Dodatni podatki",
    description: {
      basic: "Osnovni podatki",
      current_employment: "Podatki o trenutni zaposlitvi",
      wanted_employment: "Podatki o želeni zaposlitvi"
    },
    fields: {
      email: {
        label: "Elektronski naslov",
        placeholder: "Vpišite elektronski naslov"
      },
      name: {
        label: "Ime in priimek",
        placeholder: "Vpišite ime in priimek kandidata"
      },
      gender: {
        title: "Spol",
        male: "Moški",
        female: "Ženski"
      },
      birthday: {
        label: "Datum rojstva"
      },
      education: {
        label: "Stopnja izobrazbe"
      },
      career: {
        label: "Trenutni karierni nivo"
      },
      source: {
        label: "Identificiran"
      },
      linkedin_url: {
        label: "LinkedIn povezava"
      },
      employer: {
        label: "Trenutni delodajalec"
      },
      industries: {
        label: "Industrija"
      },
      workfields: {
        label: "Področje dela"
      },
      expectations: {
        label: "Želena letna bruto plača (EUR)"
      },
      region: {
        label: "Regija"
      },
      phone: {
        label: "Telefonska številka"
      },
      position_title: {
        label: "Naziv trenutnega delovnega mesta"
      },
      address: {
        label: "Naslov"
      },
      city: {
        label: "Mesto"
      },
      postal: {
        label: "Poštna številka"
      },
      country: {
        label: "Država"
      },
      education_summary: {
        label: "Povzetek izobraževanja"
      },
      notice_days: {
        label: "Odpovedni rok (št. dnevov)"
      }
    },
    add_button: "Shrani kandidata",
    update_button: "Shrani kandidata"
  },
  prompts: {
    delete: {
      title: "Izbris kandidata {name}",
      message: "Ste prepričani da želite izbrisati kandidata {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili kandidata {name}",
    updated: "Uspešno ste posodobili kandidata {name}",
    deleted: "Uspešno ste izbrisali kandidata {name}",
    not_found: "Kandidat ni bil najden"
  }
}
