export default {
  views: {
    list: {
      header: {
        title: "Predloge obvestil",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj predlogo"
      },
      table: {
        title: "Ime predloge",
        subject: "Zadeva",
        view: "Modul",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Nazadnje posodobil",
        active: "Aktivna"
      }
    },
    edit: {
      welcome_message: "Tukaj lahko izberete podatke, ki jih želite uporabiti v predlogi.",
      header: {
        list_back_button: "Na listo predlog obvestil",
        title: "Nova predloga obvestil",
        save_button: "Shrani",
        remove_button: "Izbriši"
      },
      menu: {
        headline: "Splošno",
        headline_desc: "Osnovni podatki",
        title: "Ime predloge",
        subject: "Zadeva",
        view: "Modul predloge dokumenta"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris predloge {title}",
      message: "Ste prepričani da želite izbrisati predlogo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili predlogo obvestil {title}",
    updated: "Uspešno ste posodobili predlogo obvestil {title}",
    deleted: "Uspešno ste izbrisali predlogo obvestil {title}",
    not_found: "Predloga obvestil ni bila najdena"
  }
}
