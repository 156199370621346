export default {
  auth: {
    login: {
      headline: "Competo DIGI",
      headline_desc: "Prijava v aplikacijo",
      forgot_password: "Ste pozabili geslo?",
      forgot_password_description: "Nič hudega. Geslo vam lahko ponastavimo.",
      remember_me: "Zapomni si me",
      login: "Prijava",
      fields: {
        username: {
          label: "Uporabniško ime",
          placeholder: "competo/uporabnik"
        },
        password: {
          label: "Geslo"
        }
      }
    },
    forgot_password: {
      headline: "Ponastavitev gesla",
      headline_desc: "Na elektronski naslov boste prejeli spletno povezavo za ponastavitev gesla za prijavo",
      submit: "Pošljite elektronsko pošto",
      fields: {
        email: {
          label: "Elektronski naslov",
          placeholder: "uporabnik@competo.si"
        }
      },
      remember_credentials: {
        headline: "Poznate svoje uporabniške podatke?",
        headline_desc: "Vrnite se na stran z obrazcom in prijavo dokončajte s pravilno kombinacijo uporabniškega imena ter gesla.",
        button: "Nazaj na prijavo"
      },
      message: {
        "1": "Poslali smo vam email s povezavo za ponastavitev gesla.",
        "2": "Email bi se moral kmalu pojaviti v vašem e-poštnem nabiralniku.",
        "3": "Kliknite tukaj za ponovno pošiljanje, če opazite, da email ni prispel."
      }
    },
    reset_password: {
      headline: "Ponastavitev gesla",
      headline_desc: "Na elektronski naslov boste prejeli spletno povezavo za ponastavitev gesla za prijavo",
      remember_credentials: {
        headline: "Poznate svoje uporabniške podatke?",
        headline_desc: "Vrnite se na stran z obrazcom in prijavo dokončajte s pravilno kombinacijo uporabniškega imena ter gesla.",
        button: "Nazaj na prijavo"
      },
      submit: "Ponastavi geslo",
      fields: {
        email: {
          label: "Elektronski naslov",
          placeholder: "uporabnik@competo.si"
        },
        password: {
          label: "Novo geslo"
        }
      },
      error_message: "Geslo mora vsebovati najmanj 6 znakov, vsaj eno črko, številko in poseben znak.",
      notify: {
        success: "Vaše geslo je bilo spremenjeno!",
        error: "Med spreminjanjem gesla je prišlo do napake."
      }
    }
  }
}
